<template>
<div class="modal fade " id="productLicenseCreateOrUpdateModal" tabindex="-1" role="dialog" aria-labelledby="productLicenseCreateOrUpdateModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">License Information</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <h5 class="my-1 text-uppercase">License Info</h5>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>License Key</label>
                                <input v-model="licenseKey" type="text" class="form-control" placeholder="Enter License">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select License Type</label>
                                    <VueMultiselect :allow-empty="false" v-model="selectedLicenseTypeOption" class="multiselect-blue" :options="licenseTypeOptions" :close-on-select="true" placeholder="Select Warehouse" label="name" track-by="name" :show-labels="false" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select Period</label>
                                    <VueMultiselect :allow-empty="false" v-model="selectedPeriod" class="multiselect-blue" :options="periodOptions" :close-on-select="true" placeholder="Select Warehouse" label="name" track-by="name" :show-labels="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="productLicenseIsExist ? updateProductItemLicenses() : createProductItemLicenses()">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>
                <!-- data-dismiss="modal" -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
// import VueMultiselect from "vue-multiselect";
export default {
    name: "ProductLicenseCreateOrUpdateModal",
    mixins: [ShowToastMessage, Loader],
    components: {
        // VueMultiselect,
    },
    props: [''],
    emits: ['getProductItem'],
    data() {
        return {
            selectedPeriod: {},
            licenseKey: '',
            productLicenseIsExist: false,
            selectedLicenseTypeOption: {},
            licenseTypeOptions: [{
                    value: 0,
                    name: 'Subscription'
                },
                {
                    value: 1,
                    name: 'Period'
                }
            ],
            periodOptions: [{
                    value: '+7 days',
                    name: '7 Days'
                },
                {
                    value: '+15 days',
                    name: '15 Days'
                },
                {
                    value: '+30 days',
                    name: '30 days'
                },
                {
                    value: '+3 months',
                    name: '3 months'
                },
                {
                    value: '+6 months',
                    name: '6 months'
                },
                {
                    value: '+1 year',
                    name: '1 year'
                },
                {
                    value: '+2 years',
                    name: '2 years'
                },
                {
                    value: '+3 years',
                    name: '3 years'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            //   previousRoute: 'previousRoute',
            productItem: 'appProductItems/productItem',
            productLicense: 'appProductLicenses/productLicense',
        }),
    },
    watch: {
        productItem(item) {

            this.productLicenseIsExist = item ?.productLicense ?.id ?? false;
            this.licenseKey = item ?.productLicense ?.license_key ?? '';
            const actualLicenseType = this.licenseTypeOptions.find(licenseOption => licenseOption.name == item ?.productLicense ?.license_type);
            this.selectedLicenseTypeOption = actualLicenseType !== undefined ? actualLicenseType : {};
            const actualPeriod = this.periodOptions.find(periodOption => periodOption.value == item ?.productLicense ?.period);
            this.selectedPeriod = actualPeriod !== undefined ? actualPeriod : {};

            console.log(item, "called");
        },
       
    },
    methods: {
        ...mapActions({

            postProductLicense: 'appProductLicenses/postProductLicense',
            putProductLicense: 'appProductLicenses/putProductLicense',
        }),
        async getSettingList(settingParams) {
            await this.getSettings(settingParams);
        },
        async createProductItemLicenses() {
            const dataObj = {
                product_item_id: this.productItem.id,
                license_type: this.selectedLicenseTypeOption.value,
                period: this.selectedPeriod.value,
                license_key: this.licenseKey,
            };
            this.loader(true);
            const response = await this.postProductLicense(dataObj);
            this.loader(false);
            if (response.status === 201) {
                document.querySelector('[data-target="#productLicenseCreateOrUpdateModal"]').click();
                this.$emit('getProductItem', this.$route.params.id);
                this.showToastMessage({
                    type:'success',
                    message:'Created successfully'
                });
               
            }

            if (response.message) {
                this.showToastMessage(response);
            }

        },
        async updateProductItemLicenses() {
            const dataObj = {
                id: this.productItem.productLicense.id,
                data: {
                    product_item_id: this.productItem.id,
                    license_type: this.selectedLicenseTypeOption.value,
                    period: this.selectedPeriod.value,
                    license_key: this.licenseKey,
                },
            };
            this.loader(true);
            const response = await this.putProductLicense(dataObj);
            this.loader(false);
            if (response.status === 200) {
                document.querySelector('[data-target="#productLicenseCreateOrUpdateModal"]').click();
                this.$emit('getProductItem',this.$route.params.id);
                this.showToastMessage({
                    type:'success',
                    message:'Updated successfully'
                });
            }

            if (response.message) {
                this.showToastMessage(response);
            }

        },

    },
}
</script>

<style scoped>
</style>
