<template>
  <AppLayout>
    <template v-slot:appContent>
      <div >
        <div class="content-body">
          <section class="page-user-profile">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-lg-4 ">
                    <div class="card ">
                      <div class="card-body">
                        <div class="d-flex align-items-center mb-1">
                          <div>
                            <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">License
                              information</h5>
                          </div>
                          <div class="ml-1" style="cursor: pointer;">
                            <span v-if="can('product-item-update')" data-toggle="modal"
                                  data-target="#productLicenseCreateOrUpdateModal" title="Edit"
                                  class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span>
                            <span v-else title="Edit"><i class='bx bx-edit-alt'></i></span>
                          </div>
                        </div>
                        <ul class="list-unstyled">
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">License Type: </span>
                            <span>{{ initialProductItemData.productLicense.license_type ? initialProductItemData.productLicense.license_type : 'Not Provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">License key: </span>
                            <span>{{ initialProductItemData.productLicense.license_key  ? initialProductItemData.productLicense.license_key  : 'Not Provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">License Period: </span>
                            <span class="">{{ initialProductItemData.productLicense.period  ? initialProductItemData.productLicense.period  : 'Not Provided' }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 mb-4">
                    <div class="tab-content  ">
                      <div aria-labelledby="profile-tab">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-flex align-items-center">
                              <h4 class="">Item Information</h4>
                              <span title="Edit" data-toggle="modal" data-target="#productItemEditModal"
                                    class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span>
                            </div>
                            <ul class="">
                              <li class="mb-1">
                                <div class="d-flex align-items-center text-capitalize">
                                  <div>
                                    <span class="text-bold-700 mb-1"
                                          style="text-transform: capitalize;">Product : </span>
                                    <span>{{ initialProductItemData.product }}</span>
                                  </div>
                                </div>
                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1 text-capitalize"
                                          style="text-transform: capitalize;">Serial No: </span>
                                    <span>{{ initialProductItemData.serial_number ? initialProductItemData.serial_number : 'Not  Provided' }}</span>
                                  </div>
                                </div>
                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1">Model No : </span>
                                    <span>{{ initialProductItemData.model_number ? initialProductItemData.model_number : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1">SKU : </span>
                                    <span>{{ initialProductItemData.sku ? initialProductItemData.sku : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1">Bar Code : </span>
                                    <span>{{ initialProductItemData.barcode ? initialProductItemData.barcode : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1">QR Code : </span>
                                    <span>{{ initialProductItemData.qr_code ? initialProductItemData.qr_code : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1">Manufacturing Code : </span>
                                    <span>{{ initialProductItemData.manufacturing_code ?  initialProductItemData.manufacturing_code : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1">Purchase Cost : </span>
                                    <span>{{ initialProductItemData.purchase_cost ? `$ ${initialProductItemData.purchase_cost}` : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span class="text-bold-700 mb-1">Condition : </span>
                                    <span>{{ initialProductItemData.condition ? initialProductItemData.condition : 'Not Provided' }}</span>
                                  </div>
                                </div>
                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center text-capitalize">
                                  <div>
                                    <span class="text-bold-700 mb-1">State : </span>
                                    <span>{{ initialProductItemData.state ? initialProductItemData.state : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                              <li class="mb-1">
                                <div class="d-flex align-items-center text-capitalize">
                                  <div>
                                    <span class="text-bold-700 mb-1">Warehouse : </span>
                                    <span>{{ initialProductItemData.warehouse.name ? initialProductItemData.warehouse.name : 'Not Provided' }}</span>
                                  </div>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ProductItemEditModal @updateProductItemData="onUpdateProductItemData($event)"
                                  :product-item="{...initialProductItemData}"/>
            <ProductLicenseCreateOrUpdateModal @getProductItem="getSingleProductItem($event)"/>
          </section>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {mapActions, mapGetters} from "vuex";
import ProductItemEditModal from "@/views/backEnd/products/productItems/includes/ProductItemEditModal";
import ProductLicenseCreateOrUpdateModal from "@/views/backEnd/products/productItems/includes/ProductLicenseCreateOrUpdateModal";
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  components: {
    AppLayout,
    ProductItemEditModal,
    ProductLicenseCreateOrUpdateModal

  },
  mixins: [ShowToastMessage, Authorization, Loader],
  name: "ViewProductItem",
  data() {
    return {
      image: '',
      productItemID: null,
      getProductItemParams: {
        name: '',
        with_relation: ['product', 'warehouse','productLicense'],
        order_by_id: 'desc',
        paginate: 1,
        pagination: '',
        page: '',
        status: ''
      },
      initialProductItemData: {
        product: '',
        serial_number: '',
        model_number: '',
        sku: '',
        barcode: '',
        qr_code: '',
        manufacturing_code: '',
        purchase_cost: '',
        condition: '',
        state: '',
        warehouse: {
          id:'',
          name:''
        },
        productLicense:{
          id:'',
          license_key:'',
          license_type:  '',
          period:  '',
        }
      },
      getSettingsParams: {
        type: ['product_item'],
        key: ['product_item_condition'],
      },
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      // productItem: 'appProductItems/productItem',
    }),
    conditionOptions() {
      let condition = this.$store.getters['appSettings/settingProductItemCondition'].value.map((item) => {
        let value = item.value;
        let name = item.name;
        return {value: value, name: name};
      });
      return [{value: '', name: 'None'}, ...condition];
    }
  },
  methods: {
    ...mapActions({
      getProductItem: 'appProductItems/getProductItem',
      putProductItem: 'appProductItems/putProductItem',
      getSettings: 'appSettings/getSettings',
    }),
    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async initialDataObj(productItem) {
        this.productItemID = productItem.id;
        this.initialProductItemData = {
        product: productItem?.product?.name ?? '',
        serial_number: productItem.serial_number,
        model_number: productItem.model_number,
        sku: productItem.sku,
        barcode: productItem.barcode,
        qr_code: productItem.qr_code,
        manufacturing_code: productItem.manufacturing_code,
        purchase_cost: parseFloat(productItem.purchase_cost/100).toFixed(2),
        condition: productItem.condition,
        state: productItem.state,
        warehouse: {
          id: productItem?.warehouse?.id ?? '',
          name: productItem?.warehouse?.name ?? '',
        },
        productLicense:{
          id: productItem?.productLicense?.id ?? '',
          license_key: productItem?.productLicense?.license_key ?? '',
          license_type: productItem?.productLicense?.license_type ?? '',
          period: productItem?.productLicense?.period ?? '',
        }

        
      };
    },
    async getSingleProductItem(id) {
      const paramObj = {
        id: id,
        params: this.getProductItemParams,
      };
      this.loader(true);
      await this.getProductItem(paramObj).then((response) => {
        this.loader(false);
        if (response.status === 200) {
          // getting product from product getter
          let productItem = this.$store.getters['appProductItems/productItem'];

          this.initialDataObj(productItem);

        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    async onUpdateProductItemData(updatedData) {
      console.log(updatedData);
      if (updatedData) {
        await this.initialDataObj(updatedData);
      }
    },
    selectedOptionFunction(haystack, needle) {
      if (Array.isArray(haystack)) {
        let conditionIndex = haystack.findIndex(item => item.value === needle);
        if (conditionIndex !== -1) {
          let status = haystack[conditionIndex];
          return {
            value: status.value,
            name: status.name
          };
        }
      }
      return {};
    }
  },
  async mounted() {
    const routeParams = this.$route.params ?? null;
    await this.getSingleProductItem(routeParams.id);
  },

}
</script>

<style scoped>
p {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 600;
}

.update-image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #5a8dee;
  border: 2px solid white;
  top: 79%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
  background-color: #4173ce;
}

.update-image-avatar > i {
  font-size: 20px;
  z-index: 10;
  color: white;
}

@media only screen and (max-width: 576px) {
  .update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 64%;
    left: 92px;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

.single-edit-btn {
  cursor: pointer;
}
</style>