<template>
  <div class="modal fade " id="productItemEditModal" tabindex="-1" role="dialog" aria-labelledby="productDetailsModal"
       aria-hidden="true" >
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h5 class="my-1 text-uppercase">Basic Info</h5>

            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Serial No</label>
                  <input v-model="putProductItemData.serial_number" type="text" class="form-control"
                         placeholder="Enter Serial No">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Select Warehouse</label>
                    <VueMultiselect :allow-empty="false" v-model="selectedWarehouse" class="multiselect-blue"
                                    :options="warehouseOptions"
                                    :close-on-select="true" placeholder="Select Warehouse" label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Model No</label>
                  <input v-model="putProductItemData.model_number" type="text" class="form-control"
                         placeholder="Enter Model No">
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>SKU</label>
                    <input v-model="putProductItemData.sku" type="text" class="form-control"
                           placeholder="Enter SKU">
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Bar Code</label>
                    <input v-model="putProductItemData.barcode" type="text" class="form-control"
                           placeholder="Enter Bar Code">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>QR Code</label>
                    <input v-model="putProductItemData.qr_code" type="text" class="form-control"
                           placeholder="Enter QR Code">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Manufacturing Code</label>
                    <input v-model="putProductItemData.manufacturing_code" type="text" class="form-control"
                           placeholder="Enter Manufacturing Code">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Select State</label>
                    <VueMultiselect v-model="selectedState" class="multiselect-blue" :options="stateOptions"
                                    :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Purchase Price</label>
                    <input v-model="putProductItemData.purchase_cost" type="text" class="form-control"
                           placeholder="Enter Purchase Cost">
                    <div class="text-danger" v-if="errors.purchase_cost">{{ errors.purchase_cost }}</div>
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Status</label>
                    <VueMultiselect :allow-empty="false" v-model="selectedCondition" class="multiselect-blue"
                                    :options="conditionOptions"
                                    :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateProductItem">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
          <!-- data-dismiss="modal" -->
        </div>
      </div>
    </div>

  </div>

</template>
<div></div>
<script>
import {mapActions, mapGetters} from "vuex";
import VueMultiselect from "vue-multiselect";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "ProductItemEditModal",
  mixins: [ShowToastMessage],
  components: {
    VueMultiselect,
  },
  props: ['productItem'],
  emits: ['updateProductItemData'],
  data() {
    return {
      putProductItemData: {
        product: '',
        serial_number: '',
        model_number: '',
        sku: '',
        barcode: '',
        qr_code: '',
        manufacturing_code: '',
        purchase_cost: '',
        condition: '',
        warehouse_id: '',
        state: '',
      },
      selectedCondition: {
        value: '',
        name: 'None'
      },
      selectedWarehouse: {
        value: '',
        name: 'None'
      },
      selectedState: {
        value: '',
        name: 'None'
      },
      getSettingsParams: {
        type: ['product_item', 'default'],
        key: ['product_item_condition', 'default_state'],
      },
      errors: {
        product_id: '',
        serial_number: '',
        model_number: '',
        sku: '',
        barcode: '',
        qr_code: '',
        manufacturing_code: '',
        purchase_cost: '',
        condition: '',
        warehouse_id: '',
        state: '',
      }

    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',

    }),
    conditionOptions() {
      let conditions = this.$store.getters['appSettings/settingProductItemCondition'].value.map((item) => {
        let value = item.value;
        let name = item.name;
        return {value: value, name: name};
      });
      return [{value: '', name: 'None'}, ...conditions];
    },
    stateOptions() {
      let states = this.$store.getters['appSettings/settingDefaultState'].value;
      return [{value: '', name: 'None'}, ...states];
    },
    warehouseOptions() {
      let warehouses = this.$store.getters['appWarehouses/warehouses'].map((warehouse) => {
        let id = warehouse.id;
        let name = warehouse.name;
        return {value: id, name: name};
      })
      return [{value: '', name: 'None'}, ...warehouses]
    }
  },
  watch: {
    async productItem(value) {
      if (value.condition) {
        let conditionIndex = this.conditionOptions.findIndex((item) => item.name === value.condition);
        if (conditionIndex !== -1) {
          let status = this.conditionOptions[conditionIndex];
          this.selectedCondition = {
            value: status.value,
            name: status.name
          };
        }
      }
      if (value.state) {
        let stateIndex = this.stateOptions.findIndex((item) => item.value === value.state);
        if (stateIndex !== -1) {
          let status = this.stateOptions[stateIndex];
          this.selectedState = {
            value: status.value,
            name: status.name
          };
        }
      }
      if (value.warehouse) {
        console.log(value.warehouse.id);
        let warehouseIndex = this.warehouseOptions.findIndex((item) => item.value === value.warehouse.id);
        if (warehouseIndex !== -1) {
          let status = this.warehouseOptions[warehouseIndex];
          this.selectedWarehouse = {
            value: status.value,
            name: status.name
          };
        }
      }

      this.putProductItemData = {
        serial_number: value.serial_number,
        model_number: value.model_number,
        sku: value.sku,
        barcode: value.barcode,
        qr_code: value.qr_code,
        manufacturing_code: value.manufacturing_code,
        purchase_cost: value.purchase_cost,
      };
    },
    selectedCondition() {
      this.putProductItemData.condition = this.selectedCondition.value;
    },
    selectedWarehouse() {
      this.putProductItemData.warehouse_id = this.selectedWarehouse.value;
    },
    selectedState() {
      this.putProductItemData.state = this.selectedState.value;
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      putProductItem: 'appProductItems/putProductItem',

      getWarehouses: 'appWarehouses/getWarehouses',
    }),
    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async updateProductItem() {
      const productItemPramObj = {
        id: this.$route.params.id,
        data: this.putProductItemData,
      };

      await this.putProductItem(productItemPramObj).then(async (response) => {
        if (response.status === 200) {
          document.querySelector('[data-target="#productItemEditModal"]').click();
          let toastObj = {message: 'product item related info has Updated', type: 'success'};
          let productItem = this.$store.getters['appProductItems/productItem'];
          this.$emit('updateProductItemData', productItem);
          this.showToastMessage(toastObj);
        }
        this.errors.product_id = response?.errors?.product_id?.[0] ?? '';
        this.errors.state = response?.errors?.state?.[0] ?? '';
        this.errors.warehouse_id = response?.errors?.warehouse_id?.[0] ?? '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
  },
  async mounted() {
    await this.getSettingList(this.getSettingsParams);
    await this.getWarehouses();
  }
}
</script>

<style scoped>

</style>